<template>
  <el-card class="box-card" shadow="never">
    <div slot="header" class="clearfix">
      <span>节点 - {{ currentIndex + 1 }}</span>

      <el-button
        style="float: right;margin-left:10px "
        type="danger"
        size="small"
        @click="onRemove(currentIndex)"
        >删除</el-button
      >
      <el-button
        style="float: right;margin-left:10px "
        type="info"
        size="small"
        @click="initFormOptions"
        >刷新选单</el-button
      >
      <el-button
        style="float: right; "
        type="primary"
        size="small"
        @click="onSave"
        >保存</el-button
      >
    </div>
    <el-form ref="form" :model="form" label-width="120px">
      <LocaleFormTab>
        <template #cn>
          <el-form-item label="标题">
            <el-input v-model="form.title.cn"></el-input>
          </el-form-item>
          <!-- keywords -->
          <el-form-item label="副标题">
            <el-input v-model="form.subTitle.cn"></el-input>
          </el-form-item>
          <!-- description -->
          <el-form-item label="描述">
            <el-input v-model="form.description.cn"></el-input>
          </el-form-item>
        </template>
        <template #en>
          <el-form-item label="标题">
            <el-input v-model="form.title.en"></el-input>
          </el-form-item>
          <el-form-item label="副标题">
            <el-input v-model="form.subTitle.en"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.description.en"></el-input>
          </el-form-item>
        </template>
      </LocaleFormTab>
      <el-form-item label="次序">
        <el-input v-model="form.seq" type="number"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select
          v-model="form.typeId"
          placeholder="请选择类型"
          class="form-select"
          clearable
        >
          <el-option
            v-for="item in pageSectionTypeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文本">
        <el-input v-model="form.content"></el-input>
      </el-form-item>

      <el-form-item label="图片">
        <SingleImgUpload class="logo" v-model="form.imageUrl" />
      </el-form-item>
      <el-form-item label="视频">
        <SingleImgUpload class="logo" v-model="form.videoUrl" />
      </el-form-item>
      <el-form-item label="视频封面">
        <SingleImgUpload class="logo" v-model="form.videoCoverUrl" />
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import LocaleFormTab from "@/components/LocaleFormTab";
import SingleImgUpload from "@/components/SingleImgUpload";
import options from "@/views/pagesManager/mixins/options";
// import _lodash from "lodash";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  components: {
    LocaleFormTab,
    SingleImgUpload,
  },
  mixins: [options],
  data() {
    return {
      currentIndex: 0,
      form: {},
    };
  },
  watch: {
    data: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          // let datas = _lodash.cloneDeep(val);
          this.form = { ...val };
        } else {
          this.form = {};
        }
      },
      immediate: true,
      deep: true,
    },
    index: {
      handler(val) {
        this.currentIndex = val;
      },
      immediate: true,
    },
  },
  created() {
    this.initFormOptions();
  },
  methods: {
    onRemove(index) {
      this.$emit("remove", index);
    },
    onSave() {
      this.$emit("input", this.form, this.currentIndex);
      this.$emit("save", this.form, this.currentIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin: 10px 0;
}
.logo {
  padding: 5px;
  ::v-deep {
    .el-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-image__inner {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.logo-dark {
  background-color: #333;
  &:hover {
    background-color: #000;
  }
}
</style>
