import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["pageTypeOptions", "pageSectionTypeOptions"]),
  },
  methods: {
    ...mapActions({
      refreshTypes: "page/refreshTypes",
      refreshPageSectionTypes: "page/refreshPageSectionTypes",
    }),
    initFormOptions() {
      this.refreshTypes();
      this.refreshPageSectionTypes();
    },
  },
}