import store from "../store";
export function useBuildPageForm() {
  let localeColumns = store.getters.localeColumns;
  return {
    name: "",
    typeId: null,
    title: {
      ...localeColumns,
    },
    parentId: null,
    description: {
      ...localeColumns,
    },
    subTitle: {
      ...localeColumns,
    },
    sections: [],
    hidden: false,
    icon: "",
    seq: 0,
  };
}
export function useBuildSectionItem() {
  let localeColumns = store.getters.localeColumns;
  return {
    seq: 0,
    hidden: false,
    imageUrl: "",
    typeId: null,
    redirectUrl: "",
    videoCoverUrl: "",
    videoUrl: "",
    title: { ...localeColumns },
    icon: "",
    description: {
      ...localeColumns,
    },
    subTitle: {
      ...localeColumns,
    },
    videos: [],
    images: [],
    content: "",
  };
}