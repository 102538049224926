<template>
  <div class="page-wrapper" v-loading="loading">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>网站配置</span>
        <el-button
          style="float: right; "
          type="primary"
          size="small"
          @click="onAdd"
          >增加节点</el-button
        >
      </div>
      <el-form ref="form" :model="page" label-width="120px">
        <LocaleFormTab>
          <template #cn>
            <el-form-item label="网站标题">
              <el-input v-model="page.title.cn"></el-input>
            </el-form-item>
            <!-- keywords -->
            <el-form-item label="关键词">
              <el-input v-model="page.subTitle.cn"></el-input>
            </el-form-item>
            <!-- description -->
            <el-form-item label="站点描述词">
              <el-input v-model="page.description.cn"></el-input>
            </el-form-item>
          </template>
          <template #en>
            <el-form-item label="网站标题">
              <el-input v-model="page.title.en"></el-input>
            </el-form-item>
            <el-form-item label="关键词">
              <el-input v-model="page.subTitle.en"></el-input>
            </el-form-item>
            <el-form-item label="站点描述词">
              <el-input v-model="page.description.en"></el-input>
            </el-form-item>
          </template>
        </LocaleFormTab>
        <el-form-item label="类型">
          <el-select
            v-model="page.typeId"
            placeholder="请选择类型"
            class="form-select"
            clearable
          >
            <el-option
              v-for="item in pageTypeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>

    <el-row :gutter="10">
      <el-col
        :sm="24"
        :md="12"
        v-for="(section, index) in sections"
        :key="section.id"
      >
        <SectionForm
          :data="section"
          :index="index"
          @save="onSave"
          @input="onSectionChange"
          @remove="onRemove"
        />
        <el-card class="box-card" shadow="never" v-if="false">
          <div slot="header" class="clearfix">
            <span>节点 - {{ index + 1 }}</span>
            <el-button
              style="float: right;margin-left:10px "
              type="danger"
              size="small"
              @click="onRemove(index)"
              >删除</el-button
            >
            <el-button
              style="float: right; "
              type="primary"
              size="small"
              @click="onSave"
              >保存</el-button
            >
          </div>
          <el-form ref="form" :model="section" label-width="120px">
            <LocaleFormTab>
              <template #cn>
                <el-form-item label="标题">
                  <el-input v-model="section.title.cn"></el-input>
                </el-form-item>
                <!-- keywords -->
                <el-form-item label="副标题">
                  <el-input v-model="section.subTitle.cn"></el-input>
                </el-form-item>
                <!-- description -->
                <el-form-item label="描述">
                  <el-input v-model="section.description.cn"></el-input>
                </el-form-item>
              </template>
              <template #en>
                <el-form-item label="标题">
                  <el-input v-model="section.title.en"></el-input>
                </el-form-item>
                <el-form-item label="副标题">
                  <el-input v-model="section.subTitle.en"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                  <el-input v-model="section.description.en"></el-input>
                </el-form-item>
              </template>
            </LocaleFormTab>
            <el-form-item label="次序">
              <el-input v-model="section.seq"></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-select
                v-model="section.typeId"
                placeholder="请选择类型"
                class="form-select"
                clearable
              >
                <el-option
                  v-for="item in pageSectionTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="section.content"></el-input>
            </el-form-item>

            <el-form-item label="图片">
              <SingleImgUpload class="logo" v-model="section.imageUrl" />
            </el-form-item>
            <el-form-item label="视频">
              <SingleImgUpload class="logo" v-model="section.videoUrl" />
            </el-form-item>
            <el-form-item label="视频封面">
              <SingleImgUpload class="logo" v-model="section.videoCoverUrl" />
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SingleImgUpload from "@/components/SingleImgUpload";
import { useBuildPageForm, useBuildSectionItem } from "@/utils/page";
import LocaleFormTab from "@/components/LocaleFormTab";
import { buildI18nKeyValue } from "@/utils/common";
import options from "@/views/pagesManager/mixins/options";
import SectionForm from "@/views/pagesManager/components/SectionForm";
import {
  GetPagesById,
  GetPagesSectionsById,
  PutPages,
} from "@/views/pagesManager/api";

// import { ShowApiError } from "@/request/error";
export default {
  components: { SingleImgUpload, LocaleFormTab, SectionForm },
  mixins: [options],
  data() {
    return {
      loading: false,
      submitting: false,
      currentPageId: 3,
      sections: [],
      page: {},
    };
  },
  created() {
    this.initFormOptions();
    this.page = useBuildPageForm();
    this.initSetting();
  },
  methods: {
    initSetting() {
      this.loading = true;
      GetPagesById(this.currentPageId)
        .then((res) => {
          for (const key in res.data) {
            if (Object.hasOwnProperty.call(this.page, key)) {
              this.page[key] = res.data[key];
            }
          }
          this.page["title"] = buildI18nKeyValue(res.data.title);
          this.page["subTitle"] = buildI18nKeyValue(res.data.subTitle);
          this.page["description"] = buildI18nKeyValue(res.data.description);
          return GetPagesSectionsById(this.currentPageId);
        })
        .then((res) => {
          this.sections = [];
          res.data.forEach((item) => {
            this.sections.push({
              ...item,
              title: buildI18nKeyValue(item.title),
              subTitle: buildI18nKeyValue(item.subTitle),
              description: buildI18nKeyValue(item.description),
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildDefault() {
      return {
        typeId: 6,
        title: {
          cn: "唯格石英砖",
          en: "viewgres title",
        },
        subTitle: {
          cn: "唯格石英砖",
          en: "viewgres sub title",
        },
        description: {
          cn: "唯格石英砖",
          en: "viewgres description",
        },
        icon: "",
        imageUrl: "",
        images: [],
        videoUrl: "",
        videoCoverUrl: "",
        videos: [],
        content: "",
        redirectUrl: "",
        seq: 0,
        hidden: false,
      };
    },
    onAdd() {
      let item = useBuildSectionItem();
      item.title.cn = "未命名配置";
      this.sections.push(item);
      this.$message.success("添加成功");
    },
    onSectionChange(value,index){
      this.sections[index] = {...value}
    },
    onSave() {
      let _form = {
        ...this.page,
        sections: [...this.sections],
      };
      PutPages([
        {
          id: this.currentPageId,
          ..._form,
        },
      ]).then(() => {
        this.$message.success("保存成功");
        this.initSetting();
      });
    },
    onRemove(index) {
      this.sections.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  padding: 5px;
  ::v-deep {
    .el-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-image__inner {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.logo-dark {
  background-color: #333;
  &:hover {
    background-color: #000;
  }
}
.page-wrapper {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.box-card {
  margin: 10px 0;
}
.watermark {
  width: 100px;
  height: 100px;
  background-color: #333;
  &:hover {
    background-color: #000;
  }
}
</style>
